<template>
  <div>
    <div>
      <NavBar></NavBar>
      <Header></Header>
    </div>
    <v-container>
      <v-toolbar style="margin-bottom: 15px;" dense :rounded="true">
        <v-text-field
          hide-details
          single-line
          v-model="searchTerm"
          placeholder="Buscar por nombre de producto"
        >
        </v-text-field>
        <v-btn icon @click="filterCategory()">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-select
          style="padding-top: 20px;"
          v-model="categoryState"
          :items="['Todos', 'Activos', 'Inactivos']"
          label="Estado"
          @change="filterCategory()"
        ></v-select>
      </v-toolbar>
      <v-simple-table fixed-header height="81vh">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Categoria</th>
              <th class="text-left">Estado</th>
              <th class="text-left">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in categories" :key="index">
              <td>{{ item.get("subCategory") }}</td>
              <td>{{ getState(item) }}</td>
              <td>
                <v-btn
                  v-if="item.get('state')"
                  @click="changeState(item, false)"
                  color="error"
                >
                  Desactivar
                </v-btn>
                <v-btn v-else @click="changeState(item, true)" color="success">
                  Activar
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Header from "../../components/Header.vue";
import Parse from "parse";
export default {
  name: "Orders",
  components: {
    NavBar,
    Header
  },
  data() {
    return {
      store: null,
      storeId: null,
      categories: [],
      categoriestoFilter: [],
      pictureUrl: null,
      dataToSearch: "",
      usuarioFarmacia: {},
      subCategory: "",
      searchTerm: "",
      categoryState: "Todos"
    };
  },
  mounted() {
    this.getStoreId();
    this.getSubCategory();
    this.seeUserStore();
  },
  methods: {
    async changeState(item, state) {
      try {
        await item.set("state", state).save();
        this.$swal({
          title: "Se ha actualizado el estado",
          icon: "success"
        });
        this.getFilterCategory();
      } catch (error) {
        this.$swal({
          title: "Ah ocurrido un error",
          icon: "error",
          html: error
        });
      }
    },
    getState(item) {
      return item.get("state") ? "Activo" : "Inactivo";
    },
    filterCategory() {
      if (this.categoryState != "Todos") {
        this.getStateCategory();
      } else {
        this.categories = this.categoriestoFilter;
      }
      if (this.searchTerm.trim() != "") {
        this.getFilterCategory();
      }
    },
    getStateCategory() {
      let state = this.categoryState != "Activos" ? false : true;
      if (state) {
        this.categories = this.categoriestoFilter.filter(
          x => x.get("state") === state
        );
      } else {
        this.categories = this.categoriestoFilter.filter(
          x => x.get("state") === state || !x.get("state")
        );
      }
    },
    getFilterCategory() {
      this.categories = this.categoriestoFilter.filter(x =>
        x
          .get("subCategory")
          .trim()
          .toUpperCase()
          .startsWith(this.searchTerm)
      );
    },
    getStoreId() {
      Parse.Cloud.run("getStoreId", {
        userId: Parse.User.current().id
      }).then(result => {
        if (result.attributes.storePicture._url !== undefined) {
          this.pictureUrl = result.attributes.storePicture._url;
        } else {
          this.pictureUrl = "../assets/icons/coopharmaheart.png";
        }
        if (result != null && result.id != null) {
          this.storeId = result.id;
          this.store = result;
        }
      });
    },
    getSubCategory() {
      Parse.Cloud.run("getSubCategory", {
        userId: Parse.User.current().id,
        storeId: JSON.parse(localStorage.getItem("store")).idPharmacy
      })
        .then(result => {
          this.categoriestoFilter = result;
          this.categories = result;
        })
        .catch(err => {
          this.$swal({
            title: "Ah ocurrido un error",
            icon: "error",
            html: err
          });
        });
    },
    seeUserStore() {
      if (JSON.parse(localStorage.getItem("usuarioFarmacia"))) {
        this.usuarioFarmacia = JSON.parse(
          localStorage.getItem("usuarioFarmacia")
        );
      }
    }
  }
};
</script>
